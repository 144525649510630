<template>
  <div class="ms-auto d-flex align-items-center">
    <div class="ms-3">
      <b-form-select
        v-model="pageSizeSelected"
        :options="pageSizeOptions"
        class="form-select ps-4 pagination-pager"
        @change="changePageSize(pageSizeSelected)"
      />
    </div>
    <b-pagination
      v-model="currentPageData"
      :total-rows="totalElements"
      :per-page="pageSizeSelected"
      class="ms-3"
    >
      <template #first-text><font-awesome-icon icon="fast-backward"></font-awesome-icon></template>
      <template #prev-text><font-awesome-icon icon="step-backward"></font-awesome-icon></template>
      <template #next-text><font-awesome-icon icon="step-forward"></font-awesome-icon></template>
      <template #last-text><font-awesome-icon icon="fast-forward"></font-awesome-icon></template>
    </b-pagination>
    <div class="ms-3">
      <p class="mb-0">
        ({{totalElements || 0}})
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TablePagination',
  components: { },
  props: {
    dataSize: Number,
    pageSizes: Array,
    changePageSize: Function,
    totalElements: Number,
    fetchData: Function,
    pager: Object,
  },
  data() {
    return {
      pageSizeSelected: this.dataSize || 10,
      pageSizeOptions: this.pageSizes || [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
      ],
    };
  },
  computed: {
    currentPageData: {
      get() {
        const paging = this.pager;
        return (paging.number + 1) || 1;
      },
      set(value) {
        this.fetchData(false, value);
      },
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
